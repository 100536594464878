import React from 'react';
import 'react-toggle/style.css';
import styled from 'styled-components';

import IRhapsodyAlert from 'src/ServerEntities/IRhapsodyAlert';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import { SessionContext } from 'src/Views/SessionContext';
import { getRhapsodyAlerts } from '../ramService';
import RhapsodyAlertItem from './AlertItem';
import { ContentContainer } from './Dashboard';


const SiteContainer = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 20px;
`;


interface IProps {
    selectedTypes: string[],
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void
};

const TieInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 30px;
`;

const TieTitle = styled.div`
    font-size: 1.3em;
    font-weight: bold;
`;

const OuterContainer = styled.div`
    width: 1050px;
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
    height: 100%;
`;


const InnerContainer = styled.table`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    width: 100%;
`;


const TitleBar = styled.div`
    width: 1035§px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    padding-left: 10px;
    color: #FFF;
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
`;


const RhapsodyAlerts = (props: IProps) => {
    const { state } = React.useContext(SessionContext);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [alerts, setAlerts] = React.useState(undefined as unknown as IRhapsodyAlert[]);

    React.useEffect(() => {
        if (!alerts && !loading) {
            setLoading(true);
            getRhapsodyAlerts(state.webToken, (data: IRhapsodyAlert[]) => {
                setLoading(false);
                setAlerts(data);
                setError("");
            }, (errorMessage: string) => {
                setLoading(false);
                setError(errorMessage);
            });
        }
    }, []);

    const renderAlert = (alert: IRhapsodyAlert, index: number) => {
        return <RhapsodyAlertItem alert={alert} />
    };

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <TieInfo>
            <TieTitle>Rhapsody Integration Engine</TieTitle>
        </TieInfo>

        <SiteContainer>
            <OuterContainer>
                <TitleBar>Active Alerts</TitleBar>
                <InnerContainer>
                    {alerts && alerts.map(renderAlert)}
                </InnerContainer>
            </OuterContainer>
        </SiteContainer>
    </ContentContainer>;
};

export default RhapsodyAlerts;
