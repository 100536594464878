import 'react-toggle/style.css';
import styled from 'styled-components';

import { faCaretSquareDown, faCaretSquareUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import useDataSorter from 'src/CustomHooks/useDataSorter';
import IRhapsodyComponent from 'src/ServerEntities/IRhapsodyComponent';

const Table = styled.table`
    width: 100%;
`;

const ItemLine = styled.tr`
    display: flex;
     flex-direction: row;
    flex: 1 0 auto;
    width: 100%;
    justify-content: space-between;
`;

const ItemCell = styled.td`
    text-align: center;
    padding: 5px;
    font-size: 1em;
    font-weight: bold;
     &.error  {
        color: #F00;
    }
`;
const OuterContainer = styled.div`
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
    flex: 1 1 auto;
`;

const InnerContainer = styled.div`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
`;

const TitleBar = styled.div`
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
`;

const StateBubble = styled.div`
    border-radius: 15px;
    padding: 1px;
    padding-bottom: 2px;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    &.ERROR  {
        background-color: #F00;
        color: #FFF;
    }
    &.STOPPED  {
        background-color: #B7BEC2;
        color: #000;
    }
    &.RUNNING  {
        background-color: #5CEA5A;
        color: #FFF;
    }
     &.RETRYING  {
        background-color: #2DF6C0;
        color: #FFF;
    }
`;

const ItemHeaderCell = styled.td`
    text-align: center;
    padding: 0.1em;
    font-size: 0.9em;
    font-weight: bold;
    color: #1577AE;
`;

interface IProps {
    data: IRhapsodyComponent[]
};

const renderState = (state: string) => {
    switch (state) {
        case "ERROR": return <StateBubble className={state}>Errored</StateBubble>
        case "STOPPED": return <StateBubble className={state}>Stopped</StateBubble>
        case "RUNNING": return <StateBubble className={state}>Running</StateBubble>
        case "RETRYING": return <StateBubble className={state}>Retrying</StateBubble>
    }
    return null;
}

const RhapsodyWebServices = (props: IProps) => {
    const { data } = props;
    const [sortByColumn, setSortByColumn] = React.useState(4);
    const [sortDescending, setSortDescending] = React.useState(true);

    const webserviceToItem = (array: [string, string, number, number], index: number) => {
        return <ItemLine key={`rhapsody-web-service-${index}-${array[0]}`}>
            <ItemCell width={"60%"} style={{ textAlign: "left" }} className={array[1] === "ERROR" ? "error" : ""}>{array[0]}</ItemCell>
            <ItemCell width={"30%"}>{renderState(array[1])}</ItemCell>
            <ItemCell width={"10%"}>{array[2]}</ItemCell>
        </ItemLine>
    }

    const changeSortColumn = (columnIndex: number) => (event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const webserviceToArray = (webservice: IRhapsodyComponent, index: number): [string, string, number, number] => [
        webservice.name,
        webservice.state,
        webservice.alerts,
        index
    ];

    const sortedData = data.map(webserviceToArray).sort(useDataSorter(sortByColumn, sortDescending))

    const renderSort = () => {
        return sortDescending ? <FontAwesomeIcon icon={faCaretSquareUp} style={{ marginRight: "8px" }} /> : <FontAwesomeIcon icon={faCaretSquareDown} style={{ marginRight: "8px" }} />
    }

    return <OuterContainer>
        <TitleBar>Web Services</TitleBar>
        <InnerContainer>
            <Table>
                <thead>
                    <ItemLine>
                        <ItemHeaderCell width={"60%"}></ItemHeaderCell>
                        <ItemHeaderCell width={"30%"}></ItemHeaderCell>
                        <ItemHeaderCell width={"10%"} onClick={changeSortColumn(4)}>Alerts {renderSort()}</ItemHeaderCell>
                    </ItemLine>
                </thead>
                <tbody>
                    {sortedData.map(webserviceToItem)}
                </tbody>
            </Table>
        </InnerContainer>
    </OuterContainer>;
};

export default RhapsodyWebServices;
