import React from 'react';
import 'react-toggle/style.css';
import styled from 'styled-components';

import useDataSorter from 'src/CustomHooks/useDataSorter';
import IRhapsodyUser from 'src/ServerEntities/IRhapsodyUser';
import TableSortLabelColor from 'src/SharedComponents/Table/TableSortLabelColor';

interface IProps {
    users: IRhapsodyUser[];
}

const InnerContainer = styled.table`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    margin-bottom: 20px;
`;

const TableRow = styled.tr`
    &:nth-child(odd) {
        background-color: #DCEBF3; 
    }
    &:nth-child(even) {
        background-color: #ffffff;
    }
`;

const UserCell = styled.td`
    padding: 8px 0;
    text-align: left;
`;

const TableHeader = styled.td`
    text-align: left;
    font-weight: bold;
`;

const StateBubble = styled.div`
    border-radius: 15px;
    padding: 1px;
    padding-bottom: 2px;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    &.Active  {
        background-color: #5CEA5A;
        color: #FFF;
    }
    &.Disabled  {
        background-color: #F00;
        color: #000;
    }
`;

const RhapsodyUserTable = (props: IProps) => {
    const users = props.users;
    const [sortByColumn, setSortByColumn] = React.useState(0);
    const [sortDescending, setSortDescending] = React.useState(false);

    const changeSortColumn = (columnIndex: number) => (event: React.SyntheticEvent) => {
        event.stopPropagation();
        if (columnIndex === sortByColumn) {
            setSortDescending(!sortDescending);
        } else {
            setSortDescending(true);
        }
        setSortByColumn(columnIndex);
    };

    const userToArray = (user: IRhapsodyUser, index: number): [string, string, string, string, number] => [
        user.fullName,
        user.name,
        user.groups ? user.groups.join(", ") : "",
        user.disabled ? "Disabled" : "Active",
        index
    ];

    const userArrayToTableRow = (array: [string, string, string, string, number]) => (
        <TableRow key={array[4]}>
            <UserCell>{array[0]}</UserCell>
            <UserCell>{array[1]}</UserCell>
            <UserCell>{array[2]}</UserCell>
            <UserCell><StateBubble className={array[3]}>{array[3]}</StateBubble></UserCell>
        </TableRow>
    );

    const sortedUsers = users
        .map(userToArray)
        .sort(useDataSorter(sortByColumn, sortDescending));

    return (
        <InnerContainer>
            <thead>
                <tr style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                    <TableHeader style={{ width: "20%" }}>
                        <TableSortLabelColor
                            color="#1577AE"
                            active={sortByColumn === 0}
                            direction={sortDescending ? "descending" : "ascending"}
                            onClick={changeSortColumn(0)}
                        >
                            Full name
                        </TableSortLabelColor>
                    </TableHeader>
                    <TableHeader style={{ width: "20%" }}>
                        <TableSortLabelColor
                            color="#1577AE"
                            active={sortByColumn === 1}
                            direction={sortDescending ? "descending" : "ascending"}
                            onClick={changeSortColumn(1)}
                        >
                            Name
                        </TableSortLabelColor>
                    </TableHeader>
                    <TableHeader style={{ width: "40%" }}>
                        <span>Groups</span>
                    </TableHeader>
                    <TableHeader style={{ width: "10%" }}>
                        <TableSortLabelColor
                            color="#1577AE"
                            active={sortByColumn === 3}
                            direction={sortDescending ? "descending" : "ascending"}
                            onClick={changeSortColumn(3)}
                        >
                            Status
                        </TableSortLabelColor>
                    </TableHeader>
                </tr>
            </thead>
            <tbody>{sortedUsers.map(userArrayToTableRow)}</tbody>
        </InnerContainer>
    );
};

export default RhapsodyUserTable;
