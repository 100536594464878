import 'react-toggle/style.css';
import styled from 'styled-components';

import IRhapsodyAlertsPanel from 'src/ServerEntities/IRhapsodyAlertsPanel';
import { useNavigate } from 'react-router';



interface IStyleProps {
    value: number
};

interface IProps {
    data: IRhapsodyAlertsPanel;
};

const getNumberColor = (value: number) => {
    if (value > 5) {
        return "#ff2021";
    } else if (value > 0) {
        return "#e38801";
    }
    return "#42a73f";
};

const OuterContainer = styled.div`
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
`;

const InnerContainer = styled.table`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    width: 100%;
`;

const TitleBar = styled.div`
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
`;

const SmallNumberContainer = styled.td`
    text-align: center;
    font-size: 2.4em;
`;

const BigNumberContainer = styled.td<IStyleProps>`
    width: 50%;
    text-align: center;
    font-size: 3.1em;
    font-weight: bold;
    color: ${props => getNumberColor(props.value)};
    @media (max-width: 1279px) {
        font-size: 3em;
    }
`;

const LabelContainer = styled.td`
    text-align: center;
    font-size: 0.9em;
    font-weight: bold;
`;

const AlertsPanel = (props: IProps) => {
    const alerts = props.data;
    const navigate = useNavigate();

    const handleClick = () => {
      navigate('/Ram/Rhapsody Alerts');
    };

    return <OuterContainer>
        <TitleBar onClick={handleClick}>Alerts</TitleBar>
        <InnerContainer>
            <tbody>
                <tr>
                    <LabelContainer>24 hours</LabelContainer>
                    <BigNumberContainer value={alerts && alerts.alerts24hours}>{alerts && alerts.alerts24hours}</BigNumberContainer>
                    <BigNumberContainer value={alerts && alerts.warnings24hours}>{alerts && alerts.warnings24hours}</BigNumberContainer>
                </tr>
                <tr>
                    <td></td>
                    <LabelContainer>Alerts</LabelContainer>
                    <LabelContainer>Warnings</LabelContainer>
                </tr>
                <tr>
                    <LabelContainer>7 Days</LabelContainer>
                    <SmallNumberContainer>{alerts && alerts.alerts7days}</SmallNumberContainer>
                    <SmallNumberContainer>{alerts && alerts.warnings7days}</SmallNumberContainer>
                </tr>
            </tbody>
        </InnerContainer>
    </OuterContainer>;
};

export default AlertsPanel;
