import 'react-toggle/style.css';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";

import IRhapsodyMessageCounts from 'src/ServerEntities/IRhapsodyMessageCounts';

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
`;

const OuterContainer = styled.div`
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
    height: 100%;
`;

const InnerContainer = styled.div`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
`;

const TitleBar = styled.div`
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
`;

interface IProps {
    today: IRhapsodyMessageCounts[];
    yesterday: IRhapsodyMessageCounts[];
};

const calculateHourlyDifferences = (counts: IRhapsodyMessageCounts[]) => {
    return counts.map((item, i) => {
        const previousItem = counts[i-1];
        if (previousItem) {
            return {
                timestamp: item.timestamp,
                totalReceived: item.totalReceived - previousItem.totalReceived,
                totalProcessed: item.totalProcessed - previousItem.totalProcessed,
                totalSent: item.totalSent - previousItem.totalSent,
                totalFailed: item.totalFailed - previousItem.totalFailed
            };
        } else {
            return item;
        }
    }).slice(1);
};

export { calculateHourlyDifferences };

const MessageThroughputPanel = (props: IProps) => {
    const messageCounts = calculateHourlyDifferences(props.today);
    const yesterdayMessageCounts = calculateHourlyDifferences(props.yesterday);
    const navigate = useNavigate();

    const handleClick = () => {
      navigate('/Ram/Rhapsody Messages');
    };

    const options: ApexOptions = {
        chart: {
            type: 'line'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [5, 5, 5, 5, 5],
            curve: 'straight',
            dashArray: [0, 0, 0, 0, 0]
        },

        legend: {
            tooltipHoverFormatter: function (val, opts) {
                return val + ' - <strong>' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + '</strong>'
            }
        },

        colors: ['#FF0000', '#1577AE', '#2DF6C0', '#5CEA5A', '#B7BEC2'],
        markers: {
            size: 0,
            hover: {
                sizeOffset: 10
            }
        },
        xaxis: {
            categories: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '19', '20', '21', '22', '23'],
        },
        grid: {
            borderColor: '#f1f1f1',
        }
    };

    const series = [
        {
            name: "Failed",
            data: messageCounts ? messageCounts.map((msg) => msg.totalFailed) : [],
        },
        {
            name: "Received",
            data: messageCounts ? messageCounts.map((msg) => msg.totalReceived) : [],
        },
        {
            name: "Processed",
            data: messageCounts ? messageCounts.map((msg) => msg.totalProcessed) : [],
        },
        {
            name: "Sent",
            data: messageCounts ? messageCounts.map((msg) => msg.totalSent) : [],
        },
        {
            name: "Received Yesterday",
            data: yesterdayMessageCounts ? yesterdayMessageCounts.map((msg) => msg.totalReceived) : [],
        }
    ];

    return <ContentContainer>
        <OuterContainer>
            <TitleBar onClick={handleClick}>Message Throughput Today</TitleBar>
            <InnerContainer>
                <Chart
                    width={"100%"}
                    height={"270px"}
                    type={"line"}
                    series={series}
                    options={options} />
            </InnerContainer>
        </OuterContainer>
    </ContentContainer>;
};

export default MessageThroughputPanel;
