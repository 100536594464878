import { useNavigate } from 'react-router';
import 'react-toggle/style.css';
import IRhapsodyQueuesPanel from 'src/ServerEntities/IRhapsodyQueuesPanel';
import dateConverter from 'src/UsefulFunctions/dateConverter';
import styled from 'styled-components';

interface IStyleProps {
    value: number
};

interface IProps {
    data: IRhapsodyQueuesPanel;
};

const getNumberColor = (value: number) => {
    if (value > 5) {
        return "#ff2021";
    } else if (value > 0) {
        return "#e38801";
    }
    return "#42a73f";
};

const OuterContainer = styled.div`
    border-radius: 25px;
    border: 5px solid #44A2D6;
    padding: 2px;
`;

const InnerContainer = styled.table`
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top:20px;
    width: 100%;
`;

const TitleBar = styled.div`
    border-radius: 20px;
    background-color: #1577AE;
    padding: 5px;
    color: #FFF;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
    cursor: pointer;
`;

const SmallNumberContainer = styled.td`
    text-align: center;
    font-size: 2.4em;
`;

const SinceContainer = styled.td`
    text-align: center;
    font-size: 1.2em;
    & p {
        margin: 0;
    }
`;

const BigNumberContainer = styled.td<IStyleProps>`
    width: 50%;
    text-align: center;
    font-size: 3.1em;
    font-weight: bold;
    color: ${props => getNumberColor(props.value)};
    @media (max-width: 1279px) {
        font-size: 3em;
    }
`;

const LabelContainer = styled.td`
    text-align: center;
    font-size: 0.9em;
    font-weight: bold;
`;

const QueuesPanel = (props: IProps) => {
    const queues = props.data;
    const formattedDate = dateConverter(queues?.timestamp || '', true);

    const navigate = useNavigate();

    const navQueuesPage = () => {
      navigate('/Ram/Rhapsody Queues');
    };

    return <OuterContainer>
        <TitleBar onClick={navQueuesPage}>Queues</TitleBar>
        {queues && <InnerContainer>
            <tbody>
                <tr>
                    <BigNumberContainer value={queues.latestQueue}>{queues.latestQueue}</BigNumberContainer>
                </tr>
                <tr>
                    <LabelContainer>Change</LabelContainer>
                </tr>
                <tr style={{ display: 'flex', flexDirection: 'column' }}>
                    <SmallNumberContainer>
                        {queues.differenceToPrevious > 0 ? "+" : ""}{queues.differenceToPrevious}
                    </SmallNumberContainer>
                    <SinceContainer>
                        <p>Since: {formattedDate}</p>
                    </SinceContainer>
                </tr>
            </tbody>
        </InnerContainer>}
    </OuterContainer>;
};

export default QueuesPanel;
