import * as React from 'react';
import styled from 'styled-components';

import IAlert from 'src/ServerEntities/IAlert';
import IServerAlerts from 'src/ServerEntities/IServerAlerts';
import ErrorBox from 'src/SharedComponents/ErrorBox';
import LoadingIndicator from 'src/SharedComponents/LoadingIndicator';
import TypeHolder from 'src/Views/Ram/Components/TypeHolder';
import AlertItem from './AlertItem';

import { useLocation, useNavigate } from 'react-router';
import { ALERTS_PAGE_LIMIT } from 'src/config';
import { RolesContext } from 'src/Roles/RolesContext';
import { getFirstPathOn401RoleChange } from 'src/Roles/RolesService';
import renderSiteName from 'src/UsefulFunctions/renderSiteName';
import { SessionContext } from 'src/Views/SessionContext';
import { getClosedAlerts, getCurrentAlerts } from './ramService';
import { filterAndSortUserAlerts, sortBySelection } from './Utils/alertUtils';
import { useMediaQuery } from 'react-responsive';

const ContentContainer = styled.main`
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    @media (min-width: 1279px) {
        padding: 48px 48px 104px;
    }
    @media (max-width: 1279px) {
        margin-bottom: 120px;
    }
`;

const ServerList = styled.ul`
    margin: 0 0 24px;
`;

const ListTitle = styled.h2`
    font-size: 1.4rem;
    font-weight: 600;
`;

const EmptyText = styled.p`
    margin: 0;
`;

const AlertHeader = styled.header`
    display: flex;
    flex-direction: row;
`;

const AlertTitle = styled.div`
    background-color: #1c3e5a;
    &.closed {
        background-color: #6d2f35;
    }
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    padding: 0 4px;
    flex-wrap: wrap;
    & h2 {
        margin: 0;
        font-weight: 500;
        font-size: 1.2rem;
        @media (max-width: 1279px) {
            margin: 0 4px;
        }
        @media (min-width: 1279px) {
            width: 25%;
        }
    }
    @media (min-width: 1279px) {
        border-radius: 16px;
        padding: 0 24px;
        flex: 1 1 auto;
    }
`;

const AlertList = styled.ul`
    margin: 1px 0 12px;
    @media (min-width: 1279px) {
        margin: 12px 0 0 56px;
    }
    padding: 0;
    list-style-type: none;
`;

const ClosedIssues = styled.div`
    @media (max-width: 1279px) {
        display: none;
    }
`;

const Pageing = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: flex-end;

    .page-numbers {
        display: flex;

        p {
            display: flex;
            justify-content: center;
            margin: 0 8px;
            border-radius: 50%;
            width: 20px;
            padding: 4px;
            cursor: pointer;
        }

        .selected-page {
            background-color: #396282;;
        }
    }
`;

const PrioritiesContainer = styled.div`
    width: 40px;
    display: flex;
    align-items: center;

    @media (max-width: 1279px) {
        width: 100%;
    }
    
    .priority-1, .priority-2, .priority-3, .priority-4 {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 10px;
        padding: 10% 0; /* Maintain aspect ratio */
        border-radius: 50%;
        border: none;
        margin-right: 10px;
        @media (max-width: 1279px) {
            border-radius: 0;
            margin: 0;
            padding: 0;
            height: 5px;
            text-indent: -9999px;
        }
    }

    .priority-1 {   
        background-color: #FF2121;
    }

    .priority-2 {
        background-color: #F99600;
    }

    .priority-3 {
        background-color: #42A83F;
    }

    .priority-4 {
        background-color: #19AAE2;
    }
`;

const AlertContainer = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 1279px) {
        flex-direction: column;
    }
`;

export { AlertContainer, PrioritiesContainer };

interface IProps {
    selectedTypes: string[],
    viewUserIssues: boolean,
    changeRole: boolean,
    setChangeRole: (changeRole: boolean) => void,
    sortByDate: boolean
};

const Alerts = (props: IProps) => {
    const { selectedTypes, viewUserIssues, changeRole, setChangeRole, sortByDate } = props;
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const alertId = queryParams.get('alertId');
    const selectedAlertId = parseInt(alertId ?? '0');
    const { loggedInUser, webToken } = React.useContext(SessionContext).state;
    const [current, setCurrent] = React.useState([] as unknown as IServerAlerts[]);
    const [closed, setClosed] = React.useState([] as unknown as IServerAlerts[]);
    const [currentCount, setCurrentCount] = React.useState(0);
    const [closedCount, setClosedCount] = React.useState(0);
    const [currentOffset, setCurrentOffset] = React.useState(0);
    const [closedOffset, setClosedOffset] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState("");
    const [refresh, setRefresh] = React.useState(0);
    const currentAlertsTotalPages = Math.ceil(currentCount / ALERTS_PAGE_LIMIT);
    const closedAlertsTotalPages = Math.ceil(closedCount / ALERTS_PAGE_LIMIT);
    const roleContext = React.useContext(RolesContext);
    const navigate = useNavigate();
    const isSmall = useMediaQuery({
        query: '(max-width: 1279px)'
    });

    const nextCurrentPage = (pageNumber: number) => {
        const newOffset = (pageNumber - 1) * ALERTS_PAGE_LIMIT;
        setCurrentOffset(newOffset);
    }

    const nextClosedPage = (pageNumber: number) => {
        const newOffset = (pageNumber - 1) * ALERTS_PAGE_LIMIT;
        setClosedOffset(newOffset);
    };

    const alertToItem = (server: string, closed: boolean, refresh: number, setRefresh: (count: number) => void, selectedAlertId: number) => (alert: IAlert, index: number) => {
        return (
            <AlertContainer key={`server-alert-item-${server}-${index}`}>
                <PrioritiesContainer>
                    <div className={`priority-${alert.priority}`}>
                        {alert.priority === 0 ? "" : alert.priority}
                    </div>
                </PrioritiesContainer>
                <AlertItem
                    key={`alerts-view-alert-${index}`}
                    alert={alert}
                    server={server}
                    closed={closed}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    showCurrentIssues={true}
                    size={isSmall ? "medium" : "large"}
                    startExpanded={alert.id === selectedAlertId}
                />
            </AlertContainer>
        );
    };

    const serverToItem = (
        closed: boolean,
        refresh: number,
        setRefresh: (count: number) => void,
        selectedAlertId: number
    ) => (server: IServerAlerts, index: number) => {
        return (
            <li key={`server-${server.name}-alerts-${index}`}>
                <AlertHeader>
                    <TypeHolder className={`site-type-${server.type.toLocaleLowerCase()}`}>{server.type}</TypeHolder>
                    <AlertTitle className={closed ? "closed" : "current"}>
                        <h2>{renderSiteName(server.site, server.alias)}</h2>
                        <h2>{server.name}</h2>
                        <h2>{server.namespace}</h2>
                        <h2>{server.production}</h2>
                    </AlertTitle>
                </AlertHeader>
                <AlertList>
                    {server.alerts.map(alertToItem(server.name, closed, refresh, setRefresh, selectedAlertId))}
                </AlertList>
            </li>
        );
    };

    const checkRoleChange = async (errorMessage: string) => {
        if (changeRole) {
            const path = await getFirstPathOn401RoleChange(errorMessage, webToken, roleContext.state.ramConfiguration?.components, roleContext.state.role)
            if (path) {
                navigate(path);
            }
            setChangeRole(false);
        }
    };

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                let typesString = selectedTypes.join(',');
                getCurrentAlerts(webToken, currentOffset, typesString,  (response: any) => {
                    setLoading(false)
                    setCurrent(response.data)
                    setCurrentCount(response.count)
                    setError("")
                    setChangeRole(false);
                }, async (errorMessage: string) => {
                    checkRoleChange(errorMessage);
                    setLoading(false);
                    setError(errorMessage);
                })

                getClosedAlerts(webToken, closedOffset, typesString, (response: any) => {
                    setLoading(false)  
                    setClosed(response.data)
                    setClosedCount(response.count)
                    setError("")
                }, (errorMessage: string) => {
                    setLoading(false)
                    setError(errorMessage)
                })
                setError("");
            } catch (error: any) {
                setError(error)
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [webToken, refresh, currentOffset, closedOffset, selectedTypes]);

    const filteredCurrentAlerts = current.filter(alert => selectedTypes.length === 0 || selectedTypes.includes(alert.type));
    const filteredClosedAlerts = closed.filter(alert => selectedTypes.length === 0 || selectedTypes.includes(alert.type));
    filteredCurrentAlerts.forEach(sortBySelection(sortByDate));
    filteredClosedAlerts.forEach(sortBySelection(sortByDate));

    const currentUserAlerts = filterAndSortUserAlerts(current, loggedInUser, sortByDate);
    const closedUserAlerts = filterAndSortUserAlerts(closed, loggedInUser, sortByDate);

    const advancePage = (pageNumber: number, callback: (pageNumber: number) => void) => () => {
        callback(pageNumber);
    };

    return <ContentContainer>
        <LoadingIndicator type="Linear" show={loading} />
        {error && <ErrorBox>{error}</ErrorBox>}
        <ListTitle>Current alerts</ListTitle>
        <Pageing>
            <div className="page-numbers">
                {Array.from({ length: currentAlertsTotalPages }, (_, i) => {
                    const pageOffset = i * ALERTS_PAGE_LIMIT;
                    return (
                        currentOffset === pageOffset ? (
                            <p className="selected-page" key={`alerts-current-alert-page-${i}`}>{i + 1}</p>
                        ) : (
                            <p key={`alerts-current-alert-page-${i}`} onClick={advancePage(i + 1, nextCurrentPage)}>{i + 1}</p>
                        )
                    );
                })}
            </div>
        </Pageing>

        {(filteredCurrentAlerts.length === 0) && <EmptyText>No current alerts</EmptyText>}
        <ServerList>
            {viewUserIssues ? currentUserAlerts.map(serverToItem(false, refresh, setRefresh, selectedAlertId)) : filteredCurrentAlerts.map(serverToItem(false, refresh, setRefresh, selectedAlertId))}
        </ServerList>
        <ClosedIssues>
            <ListTitle>Closed alerts</ListTitle>
            <Pageing>
                <div className="page-numbers">
                    {Array.from({ length: closedAlertsTotalPages }, (_, i) => {
                        const pageOffset = i * ALERTS_PAGE_LIMIT;
                        return (
                            closedOffset === pageOffset ? (
                                <p className="selected-page" key={`alerts-closed-alert-page-${i}`}>{i + 1}</p>
                            ) : (
                                <p key={`alerts-closed-alert-page-${i}`} onClick={advancePage(i + 1, nextClosedPage)}>{i + 1}</p>
                            )
                        );
                    })}
                </div>
            </Pageing>
            {filteredClosedAlerts.length === 0 && <EmptyText>No closed alerts</EmptyText>}
            <ServerList>
                {viewUserIssues ? closedUserAlerts.map(serverToItem(true, refresh, setRefresh, selectedAlertId)) : filteredClosedAlerts.map(serverToItem(true, refresh, setRefresh, selectedAlertId))}
            </ServerList>
        </ClosedIssues>
    </ContentContainer>;
};

export default Alerts;